import { render, staticRenderFns } from "./HomeBannerPricing.vue?vue&type=template&id=778c9820&scoped=true"
import script from "./HomeBannerPricing.js?vue&type=script&lang=js&external"
export * from "./HomeBannerPricing.js?vue&type=script&lang=js&external"
import style0 from "./HomeBannerPricing.scss?vue&type=style&index=0&id=778c9820&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "778c9820",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotTitle: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Title/Title.vue').default,HotButton: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Button/Button.vue').default})
