export default {
  props: {
    title: {
      type: String,
    },
    price: {
      type: String,
    },
    button: {
      type: Object,
    },
  },
}
